<template>
    <div class="potential">
         <rxNavBar title="店组出房"></rxNavBar>
            <!--项目选择模块-->
            <div class="module-small" @click="showProject">
                <span class="project-name">{{
                    departmentName != undefined && departmentId != ''
                    ? departmentName
                    : '部门检索'
                }}</span>
                <img class="drop-down-image" src="../../assets/images/drop-down.png" />
            </div>
            <!--拉下菜单-->
            <van-popup v-model="isShowProjectMenu" position="bottom">
                <div style="background-color: #f7f8fa">
                    <div class="choose-company-text">选择公司</div>
                    <van-row justify="center" style="background-color: #f7f8fa">
                    <div v-for="(item, index) in companyList" :key="index">
                        <van-col span="8" offset="4" class="company-col-style"
                        ><div
                            :class="
                            checkedIndex == index
                                ? 'company-col-checked-style'
                                : 'company-text-style'
                            "
                            @click="selectCompany(item, index)"
                        >
                            {{ item.departmentName ? item.departmentName : '部门检索' }}
                        </div></van-col
                        >
                    </div>
                    </van-row>
                </div>
                <van-tree-select
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
                    :items="items"
                    @click-nav="selectArea"
                    @click-item="selectDepartment"
                />
                <div class="search">
                    <van-button
                    style="width: 2rem; color: orangered;border-color: orangered"
                    plain
                    type="primary"
                    @click="resetDepartmentInfo"
                    >清空</van-button
                    >
                    <van-button
                    @click="searchDepartmentInfo"
                    style="width: 2.8rem;margin-left: 4rem"
                    color="linear-gradient(to right, #ff6034, #ee0a24)"
                    size="normal"
                    >
                    筛选
                    </van-button>
                </div>
            </van-popup>


         <div class="part-inputpart" style="margin-top: 10px">
			<div id="expectedDatePanel" class="part-inputpart-row" >
				<span :class=" 0==date.trim().length ? 'content-none' : 'content-have' "></span>
				<span class="part-inputpart-row-enablenon part-inputpart-row-header">日期</span>
				<span class="content-divide">|</span>
				<span class="part-inputpart-row-graytext" :class="0==date ? '': 'part-inputpart-row-redtext' " @click="clickDateShow">{{date}}</span>
                <span style="margin:0 15px;">~</span>
                <span class="part-inputpart-row-graytext" :class="0==twoDate ? '': 'part-inputpart-row-redtext' " @click="clickEndDateShow">{{twoDate}}</span>
				<img class="part-inputpart-row-right part-inputpart-row-right-timepick">
			</div>
		</div> 
        <div class="part-inputpart" style="margin-top: 10px">
			<div>
				<van-popup v-model="totalDateShow" position="bottom" class="selectTime">
					<div>
						<van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" item-height="35px"
						@confirm="dateConfirm" @cancel="dateCancel" />
					</div>
				</van-popup>
			</div>
		</div>
        <div class="part-inputpart" style="margin-top: 10px">
			<div>
				<van-popup v-model="totalEndDateShow" position="bottom" class="selectTime">
					<div>
						<van-datetime-picker v-model="endDate" type="year-month" title="选择年月" item-height="35px"
						@confirm="dateConfirm" @cancel="dateCancel" />
					</div>
				</van-popup>
			</div>
		</div>




            <div class="businessModuleCardTitle">店组出房排行榜</div>
            <div style="width:90%;margin:0 auto;background:#ffffff;border-radius: 8px;">
                <van-list v-model:loading="loading" :finished="finished" @load="getStaffSumList">
                    <div class="staffRenttitle">
                        <van-row justify="center">
                            <van-col span="4">排名</van-col>
                            <van-col span="4">大区</van-col>
                            <van-col span="6">店组</van-col>
                            <van-col span="4">单量</van-col>
                            <van-col span="5">有效单量</van-col>
                        </van-row>
                    </div>
                    <div class="content"  v-for="item,index in staffRentList" :key="item.billFeeType_id">
                        <van-row style="position:relative">
                            <van-col span="4" class="billFee">{{index+1}}</van-col>
                            <van-col span="4" class="billFee">{{item.tddddn}}</van-col>
                            <van-col span="6" class="billFee">{{item.tddn}} </van-col>
                            <van-col span="4" class="billFee">{{item.ration}} </van-col>
                            <van-col span="5" class="billFee">{{item.validOrder }} </van-col>
                        </van-row>                 
                    </div>
                    <div class="totalCount">
                        <van-row>
                            <van-col span="14" class="billFee">总计:</van-col>
                            <van-col span="4" class="billFee">{{totalSingular}}</van-col>
                            <van-col span="5" class="billFee">{{effectiveTotal}}</van-col>
                        </van-row>
            	</div>     
                </van-list>
            </div>
    </div>
</template>

<script>
import {
  TreeSelect,
  DatetimePicker,
  NavBar,
  Tab,
  Tabs,
  Search,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Tag,
  Button,
  Overlay,
  Popup,
  Rate,
  List,
  PullRefresh,
  Field, 
  Image as VanImage, ImagePreview, Collapse, CollapseItem
} from 'vant'
import BScroll from "better-scroll";
import Divider from "vant/lib/divider";
import {
    decorationDataOutOfRoomRanking,queryAuthLevelDepartment
} from "../../getData/getData";
import {getStaffId, getUserId, globaluserId, responseUtil, checkAuthList} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar"
import util from '../contract/util2'

export default {
    name: 'rankingListOfHomeowners',
      components: {
            [NavBar.name]: NavBar,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Search.name]: Search,
            [Row.name]: Row,
            [Col.name]: Col,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Tag.name]: Tag,
            [Button.name]: Button,
            [Overlay.name]: Overlay,
            [Popup.name]: Popup,
            [Field.name]: Field,
            [VanImage.name]: VanImage,
            [ImagePreview.Component.name]: ImagePreview.Component,
            [BScroll.name]: BScroll,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Divider.name]: Divider,
            [Rate.name]: Rate,
            [PullRefresh.name]: PullRefresh,
            [List.name]: List,
            rxNavBar,
            [TreeSelect.name]: TreeSelect,
            [DatetimePicker.name]: DatetimePicker,
        },
    data(){
        return{
            date:'',
            twoDate:'',
            threeDate:'',
            currentDate:this.formatDate(new Date()),
            endDate:new Date(),
            loading:false,
            finished:false,
            staffRentList:[],    //排行榜数组
            totalSingular:0,  //总单数
            effectiveTotal:0,  // 有效总数
            // sum:{},
            totalDateShow:false,   //查询日期弹窗    
            totalEndDateShow:false,
            isShowProjectMenu: false, //控制下拉菜单显示与否标识
            checkedIndex: 0,
            functionId: '394',
            departmentId:'',    //部门id
            departmentName: '',
            companyList: [],
            //新的列表接口所需数据
            departmentAuthData: {},
            activeId: -1,
            activeIndex: 0,
            items:[],
        }
    },
    mounted() {
        // this.changeDate(this.currentDate)
        // this.changeEndDate(this.endDate)
        this.queryAuthLevelDepartment()
        // this.getStaffSumList()
    }, 
    methods:{
        //选择日期
        clickDateShow(){
            this.totalDateShow=!this.totalDateShow
        },
        clickEndDateShow(){
            this.totalEndDateShow=!this.totalEndDateShow
        },
        dateConfirm(){
            this.totalDateShow=false
            this.totalEndDateShow=false
            this.getStaffSumList()
        },
        dateCancel(){
            this.totalDateShow=false
            this.totalEndDateShow=false
        },
        //日期转换
        formatDate(dateTime) {
            let year = `${dateTime.getFullYear()}`
            let month = `${dateTime.getMonth() + 1}`
            if (month < 10) {
                month = `0${dateTime.getMonth() + 1}`
            }
            return new Date(year + '-' + month)
        },
        //显示部门下拉菜单
        showProject() {
            this.isShowProjectMenu = true
        },
        //选择地区
        selectArea(value) {
            let item = this.items[value]
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.departmentId = item.value
            this.departmentName = item.text
            this.activeId = this.departmentId
        },
        //选择部门
        selectDepartment(data) {
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.departmentId = data.id
            this.departmentName = data.text
        },
        //取消选择
        cancelChangeProject() {
            // this.projectName = '集寓项目名称'
            // this.projectId = ''
            this.isShowProjectMenu = false
        },
        //确认筛选
        resetDepartmentInfo() {
            let departmentId = this.departmentAuthData.roleDepartmentId
            let item = this.departmentAuthData.list.find(
                item => item.id == departmentId
            )
            this.departmentName = item ? item.departmentName || '' : ''
            this.departmentId = departmentId
            this.isShowProjectMenu = false
            this.getStaffSumList()
        },
        //获取公司
        queryAuthLevelDepartment() {
            const that = this
            let initData = {}
            initData.staff_id = getStaffId() //员工id
            initData.function_id = '-1' //方法模块id
            queryAuthLevelDepartment(initData).then(res =>
                responseUtil.dealResponse(that, res, () => {
                that.departmentAuthData = res.data.data
                let allList = that.departmentAuthData.list
                if (allList != null) {
                    that.companyList = allList.filter(item => item.level == 1)
                }
                let departmentId = that.departmentAuthData.roleDepartmentId   //当前人员所属部门
                that.departmentId = departmentId
                let item = that.departmentAuthData.list.find(
                    item => item.id == departmentId
                )
                that.departmentName = item ? item.departmentName || '' : ''
                // that.departmentId=''
                // that.departmentName=''
                that.initOtherDepartment()
                that.getStaffSumList()
                })
            )
        },
        //选择公司，联动下方区、部
        selectCompany(item, index) {
            let that = this
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(that, '权限不足')
                return
            }
            this.checkedIndex = index
            this.departmentId = item.id
            this.activeId = this.departmentId
            this.departmentName = item.departmentName
            this.initOtherDepartment()
        },
        initOtherDepartment() {
            let that = this
            let allList = that.departmentAuthData.list  //所有店组
            let departmentId = that.departmentId  //当前人所在部门
            let threeLevelDepartments = allList.filter(item => item.level == 3)  //等级为3的部门
            let fiveLevelDepartments = allList.filter(item => item.level == 5)  //等级为5的部门
            let levelDepartments = threeLevelDepartments  //在等级为3的部门里
                // .filter(item => {                           //包含当前人所在部门id的
                // let levelTop = item.levelTop || ''
                // return levelTop.indexOf(departmentId) != -1
                // })
                .map(item => {                                  //遍历
                let result = {}
                result.children = fiveLevelDepartments   //result.children当前等级为5的部门
                    .filter(child => {                  //包含等级为5的部门id的3级部门
                    let levelTop = child.levelTop || ''
                    let levelTop1 = child.departmentName
                    let levelTop2 = item.departmentName
                    return levelTop.indexOf(',' + item.id) != -1
                    })
                    .map(child => {                     //获取包含等级为5的部门id的3级部门
                        let childResult = {}
                        childResult.text = child.departmentName
                        childResult.id = child.id
                        childResult.level = child.level
                        return childResult
                    })
                result.text = item.departmentName  //等级为3的部门的属性赋值
                result.value = item.id
                result.level = item.level
                return result
                })
            that.items = levelDepartments // 公司列表
        },
        
        //获取店组出房
        getStaffSumList(){  //区间收付款list
            let that=this
            let param={}
            param.currentPage=1
            param.numberPage='100'
            this.changeDate(this.currentDate)
            this.changeEndDate(this.endDate)
            param.beginTime=util.dateFormat(this.currentDate) //开始时间
            param.endTime=util.getCurrentMonthLast(this.endDate) //结束时间
            param.depId=that.departmentId
            param.user_id=getStaffId()
            decorationDataOutOfRoomRanking(param).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    // console.log(response.data.data)
                    that.staffRentList=response.data.data.data?response.data.data.data :[]
                    that.totalSingular = response.data.data.totalSingular
                    that.effectiveTotal = response.data.data.effectiveTotal
                    that.loading=true
                    that.finished=true
                    that.$forceUpdate()
                })
            })               
        },
        changeDate(currentDate){
            let year=new Date(currentDate).getFullYear()
            let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
            // let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
            this.date=year+'-'+month
            return  this.date
        },
        changeEndDate(currentDate){
            let year=new Date(currentDate).getFullYear()
            let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
            // let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
            this.twoDate=year+'-'+month
            return  this.twoDate
        },  
        changeNowDate(currentDate){
            let year=new Date(currentDate).getFullYear()
            let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
            // let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
            this.threeDate=year+'-'+month
            return  this.threeDate
        }, 	
        searchDepartmentInfo(){
            this.isShowProjectMenu = false
            this.getStaffSumList()
        }
    }
}
</script>

<style lang="less" scoped>
@radius: 16px;
@border: 1px solid rgb(244, 244, 244);

.potential {
  width: 100%;
  background-color: rgb(250, 250, 250);
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;

}
.part-inputpart {
		display: inline-block;
		flex-direction: column;
		border-radius: 8px;
		white-space: nowrap;
		overflow: hidden;
		font-size: 12px;
}

.part-inputpart-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background-color: #ffffff;
    margin-top: 2px;
    width: 342px;
    height: 50px;
    border-radius: 8px;
    margin-left: 15px;
    // margin-bottom: 20px;
}

.part-inputpart-row-header {
    font-weight: 800;
}

.part-inputpart-row input {
    border: 0;
    width: 100%;
}
.content-have {
    padding: 3px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #ff5d3b;
}

.content-none {
    padding: 3px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #c7c7c7;
}

.content-divide {
    font-size: 12px;
    color: #efefef;
    margin: 0 10px;
}
.part-inputpart-row-graytext {
    color: #d8d8d8;
    width: 22%;
}


.part-inputpart-row-redtext {
    width: 22%;
    color: #ff5d3b;
    font-weight: 600;
    margin:0 auto;
}

.part-inputpart-row-right {
    float: right;
    margin-right: 10px;
}


.part-inputpart-row-right-timepick {
    width: 20px;
}





.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  /*background-color:#f7f8fa;*/
  border-radius: 0.1rem;
  font-size: 17px;
}

.businessModuleCardTitle {
  color: #ff5d3b;
  font-size: 0.37333rem;
  text-align: left;
  width: 90%;
  height: 0.6rem;
  line-height: 0.6rem;
  margin-left: 5%;
  padding-top: 0.4rem;
  margin-bottom: 10px;
  z-index: 8888;
  font-weight: bolder;
}
.staffRenttitle .van-col{
      font-size:12px;
      font-weight:bold;
      padding:8px 10px;
      border:0.5px solid #fafafa;
      text-align: center;        
  }
  /* .content .van-row{
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .content .billFee,.totalCount .billFee{
        font-size:12px;
      padding:8px 0px;
      border:0.5px solid #fafafa;
      text-align: center;
  }
  .totalCount .billFeeTotal{
        font-size:12px;
      padding:8px 15px 8px 0;
      border:0.5px solid #fafafa;
      text-align: right;
  }
</style>
